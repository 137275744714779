.navbar{
  background-color: transparent;
}

.navbar.colorChange{
  background-color: #00152e;
  border-bottom: solid 2px rgba(133, 133, 133, 0.1);
}

.rec-dot_active {
  background-color:#39BEEC !important;
  box-shadow: 0 0 1px 30px var(--blue) !important;
}

.rec-dot:hover {
  box-shadow: 0 0 1px 3px var(--blue) !important;
  border: solid 2px #39BEEC;
}

button.rec-dot{
  border: solid 1px #39BEEC;
}

.main {
  width: 100%;
  height: auto;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
   -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
       animation: fadein 2s;
}

.app-wrapper {
  -webkit-animation: fadein 6s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 6s; /* Firefox < 16 */
   -ms-animation: fadein 6s; /* Internet Explorer */
    -o-animation: fadein 6s; /* Opera < 12.1 */
       animation: fadein 6s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}