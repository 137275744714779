#loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000C1A;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  transition: 0.5s ease-in-out;
}

#loader:first-child {
  animation-name: loading;
  animation-duration: 3s;
  /* animation-iteration-count: infinite; */
}

.hide {
  display: none;
  z-index: -9999 !important;
  opacity: 0;
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}